import PaneController from "../../journeys/pane_controller"

export default class extends PaneController {
  static values = {
    targetTechnologies: Array,
    body: String
  }

  static targets = ['compose', 'submit']

  initialize() {
    super.initialize()

    this.state = {
      body: this.bodyValue,
      maxMessageLength: this.maxMessageLength,
      technologies: []
    }

    this.changedState = { ...this.state }
  }

  onComposeInitialization({ detail: snapshot }) {
    this.state.body = snapshot.string
    this.state.technologies = snapshot.technologies
    this.state.maxMessageLength = snapshot.maxMessageLength

    this.snapshot = this.changedSnapshot = snapshot

    this.changedState = { ...this.state }
  }

  onContentValueChange({ detail }) {
    const { string } = detail

    this.changedState.body = string
    this.changedSnapshot = detail

    this.submitTarget.disabled = string.length === 0
  }

  onMessageLengthChange({ detail }) {
    const { maxMessageLength } = detail
    this.changedState.maxMessageLength = maxMessageLength

    if(!this.state.maxMessageLength) {
      this.state.maxMessageLength = maxMessageLength
    }
  }

  onTargetTechnologiesChange({ detail }) {
    this.changedState.technologies = detail
    this.submitTarget.disabled = detail.length === 0
  }

  save() {
    const { template, templateChanged } = this.changedSnapshot

    if(template && ['medium', 'low'].includes(template.quality) && !templateChanged) {
      this.dispatch('show', {
        target: this.element,
        detail: template.quality,
      })
    } else {
      this.saveAndHide()
    }
  }

  saveAndHide() {
    this.state = { ...this.changedState }

    this.sync(true)
    this._hide()

    this.clearQueue(false)

    this.state = { ...this.changedState }

    this.snapshot = {
      ...this.changedSnapshot,
      technologies: this.changedState.technologies
    }

    this.nextTick(() => {
      this.dispatch('restore', {
        target: this.composeTarget,
        detail: null,
      })
    })
  }

  get hasUnsavedChanges() {
    return this.changedState.body !== this.state.body || this.changedState.maxMessageLength !== this.state.maxMessageLength || this.targetTechnologiesChanged
  }

  sync(commit = false) {
    this.dispatch("sync", {
      detail: {
        stepId: this.stepIdValue,
        body: this.state.body,
        smsCount: 1,
        smsLength: this.maxMessageLength,
        coupon: this.coupon,
        commit,
        pieces: this.changedSnapshot.pieces,
        ...this.changedSnapshot,
      },
      target: document.documentElement,
    })
  }

  syncValues() {
    if (this.hasUnsavedChanges) {
      window.paneId = this.element.id
    }
  }

  cancel() {
    this._abort()
    this._hide()

    setTimeout(this.clearQueue, 1000)
  }

  afterShow() {
    this.dispatch('focus', {
      target: this.composeTarget
    })
  }

  _abort() {
    this.dispatch("sync:abort", {
      detail: this.stepIdValue,
      target: document.documentElement,
    })

    this.element.querySelector('[data-close-modal]').click()
    this.changedState = { ...this.state }

    this.dispatch('restore', {
      target: this.composeTarget,
      detail: this.snapshot
    })
  }

  onEnterKeydown({ detail: snapshot }) {
    if(snapshot.string) {
      this.saveAndHide()
    }
  }

  editTemplate() {
    this.nextTick(() => {
      this.dispatch('focus', {
        target: this.composeTarget
      })
    },)
  }

  get targetTechnologiesChanged() {
    return this.state.technologies.some(technology => !this.changedState.technologies.includes(technology))
  }
}
